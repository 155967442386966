import queryString from 'query-string'
import { useWidget } from 'context/widget.context'

const omit = blacklisted => obj =>
  Object.entries(obj)
    .filter(([key]) => !blacklisted.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {})

const foundField = (fields, aliasId) => {
  return fields.find(field => field && field.alias === aliasId)
}

export default function setInitialValues(fields, scriptAliasFields) {
  /* 
    looking for alias fields on url and direct props of widget  
  
    to pass the alias ids directly from widget script, 
    use alias_fields as key and "alias_1=value1&alias_2=value2" format as its value (like how you set the url params) 
  */

  const urlParams =
    {
      ...queryString.parse(scriptAliasFields),
      ...queryString.parse(window.location.search),
    } || {}
  const blackListParams = [
    'slug',
    'widget-type',
    'position',
    'once-per-user',
    'dir',
  ] // these keys are reserved for initializing the widget. check preview file
  const allowedParams = omit(blackListParams)(urlParams)

  if (allowedParams && Object.keys(allowedParams).length) {
    const aliasIds = Object.keys(allowedParams)
    let initialValues = {}
    aliasIds.forEach(alias => {
      const relatedField = foundField(fields, alias)
      if (relatedField) {
        initialValues = {
          ...initialValues,
          [relatedField.slug]: allowedParams[alias],
        }
      }
    })
    return initialValues
  }
}

export const hiddenFieldsValues = () => {
  const { formData, alias_fields } = useWidget()
  const hiddenFields = formData?.hiddenFields
  if (!hiddenFields || hiddenFields.length === 0) return {}
  return setInitialValues(hiddenFields, alias_fields)
}

export const setRefererAddress = () => ({
  submitter_referer_address: window.location.href,
})
